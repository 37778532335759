import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ruRU } from '@mui/material/locale';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'; // Импортируем Provider
import store from './storeRedux'; // Импортируем store

import Auth from './pages/Auth/Auth';
import Requests from './pages/Requests/Requests';
import Requisites from './pages/Requisites/Requisites';
import Penalties from './pages/Penalties/Penalties';
import AddBalance from './pages/AddBalance/AddBalance';
import Settings from './pages/Settings/Settings';
import AddReq from './pages/AddReq/AddReq';
import EditReq from './pages/EditReq/EditReq';
import AddBal from './pages/AddBal/AddBal';

const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  ruRU,
);

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div className="App assistant-400">
          <BrowserRouter>
              <Routes>
                <Route path="/" element={<Requests/>}></Route>
                <Route path="/auth/" element={<Auth/>}></Route>
                <Route path="/requisites/" element={<Requisites/>}></Route>
                <Route path="/addbalance/" element={<AddBalance/>}></Route>
                <Route path="/settings/" element={<Settings/>}></Route>
                <Route path="/penalties/" element={<Penalties/>}></Route>
                <Route path="/addreq/" element={<AddReq/>}></Route>
                <Route path='/addbal/' element={<AddBal/>}></Route>
                <Route path="/editreq/" element={<EditReq/>}></Route>
              </Routes>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
