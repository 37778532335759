import './Settings.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import colorFile from "../../assets/icons/colorFile.svg";
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import UserInfo from '../../components/UserInfo/UserInfo';
import BtnGroupHeader from '../../components/BtnGroupHeader/BtnGroupHeader';
import { useSelector, useDispatch } from 'react-redux';
import news from '../../assets/icons/news.svg';
import support from '../../assets/icons/support.svg';

export default function Settings() {

    localStorage.setItem('page', '/settings');

    const dispatch = useDispatch();
    const theme = useSelector((state) => state.APP_THEME);
    const user_code = useSelector((state) => state.USER_CODE);
    const show_sidebar = useSelector((state) => state.SHOW_SIDEBAR);
    
    const jwtToken = localStorage.getItem('accessToken');
    const navigate = useNavigate();

    useEffect(() => {
        if (!jwtToken) {
            navigate('/auth');
            localStorage.clear();
        }
    }, []);

    return (
        <div className={theme === 'light' ? "Settings" : "Settings dark"}>
            <Sidebar/>
            <div className={show_sidebar ? "content closed" : "content"}>
                <div className="header">
                    <h2 className='urbanist-500'>Настройки</h2>
                    <UserInfo/>
<BtnGroupHeader/>
                </div>
                <a href={`https://t.me/toopir_bot?start=exod${user_code}`} target='_blank' className="row">
                    <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16" cy="16" r="14" fill="url(#paint0_linear_87_7225)"/>
                        <path d="M22.9866 10.2088C23.1112 9.40332 22.3454 8.76755 21.6292 9.082L7.36482 15.3448C6.85123 15.5703 6.8888 16.3483 7.42147 16.5179L10.3631 17.4547C10.9246 17.6335 11.5325 17.541 12.0228 17.2023L18.655 12.6203C18.855 12.4821 19.073 12.7665 18.9021 12.9426L14.1281 17.8646C13.665 18.3421 13.7569 19.1512 14.314 19.5005L19.659 22.8523C20.2585 23.2282 21.0297 22.8506 21.1418 22.1261L22.9866 10.2088Z" fill="white"/>
                        <defs>
                        <linearGradient id="paint0_linear_87_7225" x1="16" y1="2" x2="16" y2="30" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#37BBFE"/>
                        <stop offset="1" stop-color="#007DBB"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    <span>Подключить телеграм оповещения</span>
                </a>
                {/* Будет добавлена ссылка позже на скачивание актуальной версии мобильного приложения */}
                <a href='https://wdfiles.ru/1tzwd' target='_blank' className="row">
                    <img src={colorFile} alt="file" />
                    <span>Скачать актуальную версию приложения</span>
                </a>
                <a href="https://t.me/+PKHJuSR-DHpiYTRi" target='_blank' className="row">
                    <img src={news} alt="news" />
                    <span>Новостной канал (официальный)</span>
                </a>
                <a href="https://t.me/Appe5l_bot" target='_blank' className="row">
                    <img src={support} alt="support" />
                    <span>Бот службы поддержки</span>
                </a>
            </div>
        </div>
    )
}