import './EditReq.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import UserInfo from '../../components/UserInfo/UserInfo';
import BtnGroupHeader from '../../components/BtnGroupHeader/BtnGroupHeader';
import { useSelector, useDispatch } from 'react-redux';
import { setAppTheme } from '../../storeRedux';

export default function EditReq() {
    const jwtToken = localStorage.getItem('accessToken');
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const theme = useSelector((state) => state.APP_THEME);
    const api_url = useSelector((state) => state.API_URL);
    const user_id = useSelector((state) => state.USER_ID);
    const data_to_edit = useSelector((state) => state.REQUISITE_DATA_TO_EDIT);
    const show_sidebar = useSelector((state) => state.SHOW_SIDEBAR);

    function findRequestsPkSubstring(inputString) {
        const start = 'Лимит: <b>';
        const end = ' руб';
    
        const startIndex = inputString.indexOf(start) + start.length;
        const endIndex = inputString.indexOf(end, startIndex);
    
        if (startIndex < start.length || endIndex === -1) {
            return 'null';
        }

        return Number(inputString.substring(startIndex, endIndex).replace(/\s+/g, ''));
    }

    const [credentials, setCredentials] = useState(data_to_edit.credentials.value);
    const [credentials_limit, setCredentials_limit] = useState(findRequestsPkSubstring(data_to_edit.total_amount.value));
    const [credential_max_rur, setCredential_max_rur] = useState(data_to_edit.max_rur.value);
    // const [onOneRequest, setOnOneRequest] = useState(data_to_edit.on_one_request.value);
    const [status, setStatus] = useState(data_to_edit.status.value);
    const [comment, setComment] = useState(data_to_edit.comment.value);
    const [canAutoclose, setCanAutoclose] = useState(data_to_edit.can_autoclose.value);
    const [paymentType, setPaymentType] = useState(data_to_edit.payment_type.value === 'ccard1' ? '1' : data_to_edit.payment_type.value === 'SBP' ? '2' : '');
    const [kind, setKind] = useState(data_to_edit.kind.value);

    useEffect(() => {
        if (!jwtToken) {
            navigate('/auth');
            localStorage.clear();
        }
    }, []);

    const handleSubmit = async () => {
        const data = {
            credentials,
            credentials_limit: parseInt(credentials_limit, 10),
            // on_one_request: onOneRequest,
            status,
            comment,
            can_autoclose: canAutoclose,
            max_rur: parseInt(credential_max_rur, 10),
            kind,
            payment_type: paymentType,
            user: user_id
        };

        try {
            const response = await fetch(api_url + `hub/managercredentials/${data_to_edit.pk}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `JWT ${jwtToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                alert('Данные успешно отправлены');
                navigate('/requisites');
            } else {
                if (response.status === 401) {
                    // Обработка ошибки 401
                    localStorage.clear();
                    navigate('/auth');
                } else {
                    alert('Ошибка при отправке данных');
                }
                
            }
        } catch (error) {
            console.error('Error submitting data:', error);
            alert('Ошибка при отправке данных');
        }
    };

    return (
        <div className={theme === 'light' ? "EditReq" : "EditReq dark"}>
            <Sidebar/>
            <div className={show_sidebar ? "content closed" : "content"}>
                <div className="header">
                    <h2 className='urbanist-500'>Изменить реквизиты</h2>
                    <UserInfo/>
<BtnGroupHeader/>
                </div>
                {Object.keys(data_to_edit).length !== 0 ? <div className="block">
                    <h2>Реквизиты</h2>
                    <input type="text" placeholder='Реквизиты' value={data_to_edit.credentials.value}/>
                    <h2>Лимит</h2>
                    <input 
                        type="text" 
                        placeholder='Лимит на реквизиты, руб' 
                        value={credentials_limit} 
                        // onChange={(e) => setCredentials_limit(e.target.value.replace(/\D/, ''))}
                        onChange={(e) => {
                            if (e.target.value.length <= 7 && e.target.value <= 1000000) {
                                setCredentials_limit(e.target.value.replace(/\D/, ''));
                            }
                        }}
                    />
                    {/* <h2>Отключить сразу после выдачи в сделку</h2>
                    <div className="turnofff" onClick={() => setOnOneRequest(!onOneRequest)}>
                        <div className={`sq ${onOneRequest ? 'selected' : ''}`}></div>
                        <span>Отключить сразу после выдачи в сделку</span>
                    </div> */}
                    <h2>Максимальная сумма заявки</h2>
                    <input 
                        type="text" 
                        placeholder='Максимальная сумма заявки' 
                        value={credential_max_rur}
                        // onChange={(e) => setCredential_max_rur(e.target.value.replace(/\D/, ''))} // Только цифры
                        onChange={(e) => {
                            if (e.target.value.length <= 6 && e.target.value <= 100000) {
                                setCredential_max_rur(e.target.value.replace(/\D/, ''));
                            }
                        }}
                    />
                    <h2>Статус</h2>
                    <div className="statuses">
                        {[['1', 'Активно'], ['2', 'Ожидание'], ['7', 'Недостаточно баланса'], ['34', 'Временный лимит по карте'], ['4', 'Лимит по карте'], ['5', 'Архив'], ['6', 'Блок']].map((s) => (
                            <button 
                                key={s[0]} 
                                className={status === s[0] ? 'selected' : ''} 
                                onClick={() => setStatus(s[0])}
                            >
                                {s[1]}
                            </button>
                        ))}
                    </div>
                    <h2>Подсказка</h2>
                    <input 
                        type="text" 
                        placeholder='Подсказка' 
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                    <h2>Автозакрытие</h2>
                    <div className="turnofff" onClick={() => setCanAutoclose(!canAutoclose)}>
                        <div className={`sq ${canAutoclose ? 'selected' : ''}`}></div>
                        <span>Автозакрытие</span>
                    </div>
                    <h2>Метод оплаты</h2>
                    <div className="statuses">
                        {[['Оплата по СБП', '2'], ['Оплата по номеру карты', '1']].map((b) => (
                            <button 
                                key={b[0]} 
                                className={paymentType === b[1] ? 'selected' : ''} 
                                onClick={() => setPaymentType(b[1])}
                            >
                                {b[0]}
                            </button>
                        ))}
                    </div>
                    <h2>Банк</h2>
                    <div className="statuses">
                        {[['', 'Не указывать'], ['tinkoff', 'Tinkoff'], ['sberbank', 'Sberbank'], ['home', 'Home Credit Bank']].map((b) => (
                            <button 
                                key={b[0]} 
                                className={kind === b[0] ? 'selected' : ''} 
                                onClick={() => setKind(b[0])}
                            >
                                {b[1]}
                            </button>
                        ))}
                    </div>
                    <button onClick={handleSubmit}>Изменить</button>
                </div> : <></>}
            </div>
        </div>
    )
}