import './UserInfo.scss'; 
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { setUserCode } from '../../storeRedux';
import { setUserID } from '../../storeRedux';
import { setIsUserActive } from '../../storeRedux';

export default function UserInfo() {
    const [userInfo, setUserInfo] = useState({});

    const dispatch = useDispatch();
    const api_url = useSelector((state) => state.API_URL);

    const navigate = useNavigate();

    const setUserCodeFunc = (some_data) => {
        const newData = some_data;
        dispatch(setUserCode(newData));
    };

    const setUserActiveFunc = (some_data) => {
        const newData = some_data;
        dispatch(setIsUserActive(newData));
    };

    const setUserIDFunc = (some_data) => {
        const newData = some_data;
        dispatch(setUserID(newData));
    };

    function getUserInfo() {
        const jwtToken = localStorage.getItem('accessToken');
        fetch(api_url + 'hub/managercredentials/user', {
            method: 'GET',
            headers: {
                'Authorization': `JWT ${jwtToken}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Обработка ошибки 401
                    localStorage.clear();
                    navigate('/auth');
                } else {
                    throw new Error('Network response was not ok');
                }
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            setUserActiveFunc(data.active);
            setUserCodeFunc(data.code);
            setUserIDFunc(data.id);
            setUserInfo(data);
            // Обновите состояние или выполните другие действия после успешного получения данных
        })
        .catch(error => {
            console.error('Error fetching user info:', error);
            // Обработайте ошибку, если запрос завершился неудачно
        });
    }
        
    useEffect(() => {
        getUserInfo(); // Первоначальный вызов функции для получения данных сразу

        const intervalId = setInterval(getUserInfo, 20000); // Установка интервала на 20 секунд

        return () => clearInterval(intervalId); // Очистка интервала при размонтировании компонента
    }, []);

    return (
        <div className="user_info">
            <span className='urbanist-700'>{userInfo.username} / {userInfo.usdt_balance} USDT / курс {userInfo.rate}</span>                       
        </div>
    );
}
