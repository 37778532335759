import { configureStore, createSlice } from '@reduxjs/toolkit';

// Начальное состояние
const initialState = {
  API_URL: '/react_admin/api/',
  API_URL_NO_RA: '/', //without react admin
  APP_THEME: 'dark',
  REQUISITE_DATA_TO_EDIT: {},
  USER_CODE: '',
  USER_ID: null,
  USER_ADD_BALANCE_WALLET: '',
  SHOW_SIDEBAR: false,
  IS_ACTIVE_USE: false
};

// Создание slice
const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppTheme: (state, action) => {
      state.APP_THEME = action.payload;
    },
    setDataOfReqToEdit: (state, action) => {
      state.REQUISITE_DATA_TO_EDIT = action.payload;
    },
    setUserCode: (state, action) => {
      state.USER_CODE = action.payload;
    },
    setUserID: (state, action) => {
      state.USER_ID = action.payload;
    },
    setUserAddBalanceWallet: (state, action) => {
      state.USER_ADD_BALANCE_WALLET = action.payload;
    },
    setShowSidebar: (state, action) => {
      state.SHOW_SIDEBAR = action.payload;
    },
    setIsUserActive: (state, action) => {
      state.IS_ACTIVE_USE = action.payload;
    }
  }
});

// Экспортируем экшены
export const {
  setAppTheme,
  setDataOfReqToEdit,
  setUserCode,
  setUserID,
  setUserAddBalanceWallet,
  setShowSidebar,
  setIsUserActive
} = appSlice.actions;

// Создание и экспорт store
const store = configureStore({
  reducer: appSlice.reducer
});

export default store;
