import './AddReq.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import UserInfo from '../../components/UserInfo/UserInfo';
import BtnGroupHeader from '../../components/BtnGroupHeader/BtnGroupHeader';
import { useSelector, useDispatch } from 'react-redux';
import { setAppTheme } from '../../storeRedux';
import Alert from '@mui/material/Alert';

export default function AddReq() {
    const jwtToken = localStorage.getItem('accessToken');
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const theme = useSelector((state) => state.APP_THEME);
    const user_id = useSelector((state) => state.USER_ID);
    const api_url = useSelector((state) => state.API_URL);
    const show_sidebar = useSelector((state) => state.SHOW_SIDEBAR);

    const [credentials, setCredentials] = useState('');
    const [credentials_limit, setCredentials_limit] = useState('');
    const [credential_max_rur, setCredential_max_rur] = useState('');
    const [onOneRequest, setOnOneRequest] = useState(false);
    const [status, setStatus] = useState('Активно');
    const [comment, setComment] = useState('');
    const [canAutoclose, setCanAutoclose] = useState(false);
    const [paymentType, setPaymentType] = useState('');
    const [kind, setKind] = useState('Не указывать');

    const [successSendingData, setSuccessSendingData] = useState(false);
    const [errorSendingData, setErrorSendingData] = useState(false);

    useEffect(() => {
        if (!jwtToken) {
            navigate('/auth');
            localStorage.clear();
        }
    }, []);

    const handleSubmit = async () => {
        const data = {
            credentials,
            credentials_limit: parseInt(credentials_limit, 10),
            on_one_request: onOneRequest,
            status,
            comment,
            can_autoclose: canAutoclose,
            kind,
            payment_type: paymentType,
            max_rur: parseInt(credential_max_rur, 10),
            user: user_id
        };
        console.log(data);
        try {
            const response = await fetch(api_url + 'hub/managercredentials/', {
                method: 'POST',
                headers: {
                    'Authorization': `JWT ${jwtToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                setSuccessSendingData(true);
                navigate('/requisites');
            } else {
                if (response.status === 401) {
                    // Обработка ошибки 401
                    localStorage.clear();
                    navigate('/auth');
                } else {
                    setErrorSendingData(true);
                }
                
            }
        } catch (error) {
            console.error('Error submitting data:', error);
            setErrorSendingData(true);
        }
    };

    return (
        <div className={theme === 'light' ? "AddReq" : "AddReq dark"}>
            {successSendingData && <Alert style={{cursor: "pointer", position: "absolute", margin: "auto", top: "16px", left: "0", right: "0", width: "max-content", zIndex: "100"}} severity="success" onClose={() => setSuccessSendingData(false)}>Успешно</Alert>}
            {errorSendingData && <Alert style={{cursor: "pointer", position: "absolute", margin: "auto", top: "16px", left: "0", right: "0", width: "max-content", zIndex: "100"}} severity="error" onClose={() => setErrorSendingData(false)}>Ошибка</Alert>}
            <Sidebar/>
            <div className={show_sidebar ? "content closed" : "content"}>
                <div className="header">
                    <h2 className='urbanist-500'>Добавить реквизиты</h2>
                    <UserInfo/>
<BtnGroupHeader/>
                </div>
                <div className="block">
                    <h2>Реквизиты</h2>
                    <input 
                        type="text" 
                        placeholder='Реквизиты' 
                        value={credentials}
                        onChange={(e) => {
                            if (e.target.value.length <= 22) {
                                setCredentials(e.target.value);
                            }
                        }}
                    />
                    <h2>Лимит</h2>
                    <input
                        type="text" 
                        placeholder='Лимит на реквизиты, руб (до 1.000.000)' 
                        value={credentials_limit}
                        // onChange={(e) => setCredentials_limit(e.target.value.replace(/\D/, ''))} // Только цифры
                        onChange={(e) => {
                            if (e.target.value.length <= 7 && e.target.value <= 1000000) {
                                setCredentials_limit(e.target.value.replace(/\D/, ''));
                            }
                        }}
                    />
                    <h2>Максимальная сумма заявки</h2>
                    <input 
                        type="text" 
                        placeholder='Максимальная сумма заявки (до 100.000)' 
                        value={credential_max_rur}
                        // onChange={(e) => setCredential_max_rur(e.target.value.replace(/\D/, ''))} // Только цифры
                        onChange={(e) => {
                            if (e.target.value.length <= 6 && e.target.value <= 100000) {
                                setCredential_max_rur(e.target.value.replace(/\D/, ''));
                            }
                        }}
                    />
                    {/* <h2>Отключить сразу после выдачи в сделку</h2>
                    <div className="turnofff" onClick={() => setOnOneRequest(!onOneRequest)}>
                        <div className={`sq ${onOneRequest ? 'selected' : ''}`}></div>
                        <span>Отключить сразу после выдачи в сделку</span>
                    </div> */}
                    <h2>Статус</h2>
                    <div className="statuses">
                        {[['1', 'Активно'], ['2', 'Ожидание'], ['7', 'Недостаточно баланса'], ['34', 'Временный лимит по карте'], ['4', 'Лимит по карте'], ['5', 'Архив'], ['6', 'Блок']].map((s) => (
                            <button 
                                key={s[0]} 
                                className={status === s[0] ? 'selected' : ''} 
                                onClick={() => setStatus(s[0])}
                            >
                                {s[1]}
                            </button>
                        ))}
                    </div>
                    <h2>Подсказка</h2>
                    <input 
                        type="text" 
                        placeholder='Подсказка' 
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                    <h2>Автозакрытие</h2>
                    <div className="turnofff" onClick={() => setCanAutoclose(!canAutoclose)}>
                        <div className={`sq ${canAutoclose ? 'selected' : ''}`}></div>
                        <span>Автозакрытие</span>
                    </div>
                    <h2>Метод оплаты</h2>
                    <div className="statuses">
                        {[['Оплата по номеру карты', '1'], ['Оплата по СБП', '2']].map((b) => (
                            <button 
                                key={b[0]} 
                                className={paymentType === b[1] ? 'selected' : ''} 
                                onClick={() => setPaymentType(b[1])}
                            >
                                {b[0]}
                            </button>
                        ))}
                    </div>
                    <h2>Банк</h2>
                    <div className="statuses">
                        {[['', 'Не указывать'], ['tinkoff', 'Tinkoff'], ['sberbank', 'Sberbank'], ['home', 'Home Credit Bank']].map((b) => (
                            <button 
                                key={b[0]} 
                                className={kind === b[0] ? 'selected' : ''} 
                                onClick={() => setKind(b[0])}
                            >
                                {b[1]}
                            </button>
                        ))}
                    </div>
                    <button onClick={handleSubmit}>Добавить</button>
                </div>
            </div>
        </div>
    )
}
