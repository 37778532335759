// Auth.js
import './Auth.scss';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { setAppTheme } from '../../storeRedux';
import logo from '../../assets/logos/logo.svg';
import AnimatedBackground from './AnimatedBackground';

export default function Auth() {
    const dispatch = useDispatch();
    const api_url = useSelector((state) => state.API_URL);
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);

    const handleLoginChange = (event) => setUsername(event.target.value);
    const handlePasswordChange = (event) => setPassword(event.target.value);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleLogin({ username, password });
        }
    };

    const handleLogin = async ({ username, password }) => {
        const request = new Request(api_url + 'token/', {
          method: 'POST',
          body: JSON.stringify({ username, password }),
          headers: new Headers({ 'Content-Type': 'application/json' }),
        });
    
        const response = await fetch(request);
        if (response.status < 200 || response.status >= 300) {
          setError(true);
          throw new Error(response.statusText);
        }
    
        const { access, refresh } = await response.json();
        localStorage.setItem('accessToken', access);
        localStorage.setItem('refreshToken', refresh);
        navigate('/');
    };
    
    return (
        <div className="Auth">
            <AnimatedBackground />
            <img src={logo} alt="logo" />
            <div className="block">
                <h2>Авторизация</h2>
                <input 
                    type="text" 
                    placeholder={'Логин'} 
                    value={username} 
                    onChange={handleLoginChange} 
                    onClick={() => setError(false)}
                    onKeyPress={handleKeyPress} // Добавили отслеживание нажатия клавиш
                />
                <input 
                    type="password" 
                    placeholder={'Пароль'} 
                    value={password} 
                    onChange={handlePasswordChange} 
                    onClick={() => setError(false)}
                    onKeyPress={handleKeyPress} // Добавили отслеживание нажатия клавиш
                />
                {error && <span>Неправильный логин / пароль</span>}
                <button onClick={() => handleLogin({username, password})}>Войти</button>
            </div>
        </div>
    );
}
