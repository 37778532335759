// AnimatedBackground.js
import React, { useRef, useEffect } from 'react';

const AnimatedBackground = () => {
    const canvasRef = useRef(null);
    const rate = 60;
    const arc = 100;
    const size = 7;
    const speed = 5;
    const colors = ['#ffffff'];
    const parts = new Array(arc);
    const mouse = { x: 0, y: 0 };

    const initializeParticles = (ctx, width, height) => {
        for (let i = 0; i < arc; i++) {
            parts[i] = {
                x: Math.ceil(Math.random() * width),
                y: Math.ceil(Math.random() * height),
                toX: Math.random() * 5 - 1,
                toY: Math.random() * 2 - 1,
                c: colors[Math.floor(Math.random() * colors.length)],
                size: Math.random() * size,
            };
        }
    };

    const animateParticles = (ctx, width, height, time) => {
        ctx.clearRect(0, 0, width, height);
        for (let i = 0; i < arc; i++) {
            const li = parts[i];
            const distanceFactor = Math.max(Math.min(15 - (distance(mouse, li) / 10), 10), 1);
            ctx.beginPath();
            ctx.arc(li.x, li.y, li.size * distanceFactor, 0, Math.PI * 2, false);
            ctx.fillStyle = li.c;
            ctx.strokeStyle = li.c;
            if (i % 2 === 0) ctx.stroke();
            else ctx.fill();

            li.x += li.toX * (time * 0.05);
            li.y += li.toY * (time * 0.05);

            // Wrap around edges
            if (li.x > width) li.x = 0;
            if (li.y > height) li.y = 0;
            if (li.x < 0) li.x = width;
            if (li.y < 0) li.y = height;
        }

        if (time < speed) {
            time++;
        }
        setTimeout(() => animateParticles(ctx, width, height, time), 1000 / rate);
    };

    const distance = (p1, p2) => {
        const dx = p2.x - p1.x;
        const dy = p2.y - p1.y;
        return Math.sqrt(dx * dx + dy * dy);
    };

    const handleMouseMove = (e) => {
        mouse.x = e.clientX;
        mouse.y = e.clientY;
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const width = (canvas.width = window.innerWidth);
        const height = (canvas.height = window.innerHeight);

        initializeParticles(ctx, width, height);
        animateParticles(ctx, width, height, 0);

        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <canvas
            ref={canvasRef}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1,
            }}
        />
    );
};

export default AnimatedBackground;
