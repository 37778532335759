import Sidebar from '../../components/Sidebar/Sidebar';
import './Penalties.scss';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import UserInfo from '../../components/UserInfo/UserInfo';
import { format, formatDate } from 'date-fns';
import BtnGroupHeader from '../../components/BtnGroupHeader/BtnGroupHeader';
import { useSelector, useDispatch } from 'react-redux';
import { setAppTheme } from '../../storeRedux';
import Alert from '@mui/material/Alert';

export default function Penalties() {

    localStorage.setItem('page', '/penalties');

    const dispatch = useDispatch();
    const theme = useSelector((state) => state.APP_THEME);
    const api_url = useSelector((state) => state.API_URL);
    const show_sidebar = useSelector((state) => state.SHOW_SIDEBAR);

    const jwtToken = localStorage.getItem('accessToken');
    const navigate = useNavigate();
    const [penaltiesData, setPenaltiesData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [errorFetchingPenalties, setErrorFetchingPenalties] = useState(false); // стейт для вывода алерта об ошибки запроса данных реквизитов

    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(1);
    const [reqRowsCount, setReqRowsCount] = useState(0);

    useEffect(() => {
        if (!jwtToken) {
            navigate('/auth');
            localStorage.clear();
        }
    }, []);

    const statusesDefault = {
        "1" : "Создан",
        "2" : "Списан"
    };

    useEffect(() => {
        const jwtToken = localStorage.getItem('accessToken');
        const getPenaltiesData = async () => {
          try {
            const response = await fetch(api_url + 'hub/penaltyuser/info', {
              headers: {
                'Authorization': `JWT ${jwtToken}`,
                'Content-Type': 'application/json'
              }
            });
            if (!response.ok) {
                if (response.status === 401) {
                    // Обработка ошибки 401
                    localStorage.clear();
                    navigate('/auth');
                }
            }
            const data = await response.json();
            setPenaltiesData(data.results);
            generateColumns(data.results);
            setReqRowsCount(data.count);
          } catch (error) {
            console.error('Error fetching data:', error);
            setErrorFetchingPenalties(true);
          }
        };
    
        getPenaltiesData();

        const intervalId = setInterval(getPenaltiesData, 20000);

        return () => clearInterval(intervalId);
    }, []);

    const generateColumns = (data) => {
        if (data.length > 0) {
            const firstItem = data[0];
            const generatedColumns = Object.keys(firstItem).slice(1).map(key => ({
                id: key,
                label: firstItem[key]?.label || key,
                minWidth: 170,
                align: 'left',
                format: (value) => <div dangerouslySetInnerHTML={{ __html: value }} />,
                formatStatus: (value) => statusesDefault[value],
                formatDate: (value) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss')
            }));
            setColumns(generatedColumns);
        }
    };
    
    function createDataFromPenalties(request) {
        let data = {};
        for (let key in request) {
            if (request.hasOwnProperty(key)) {
                data[key] = request[key].value;
            }
        }
        return data;
    }
    
    const rows = penaltiesData.map(createDataFromPenalties);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    return (
        <div className={theme === 'light' ? "Penalties" : "Penalties dark"}>
            {errorFetchingPenalties && <Alert style={{cursor: "pointer", position: "absolute", margin: "auto", top: "16px", left: "0", right: "0", width: "max-content", zIndex: "100"}} severity="error" onClose={() => setErrorFetchingPenalties(false)}>Ошибка получения данных о штрафах</Alert>}
            <Sidebar/>
            <div className={show_sidebar ? "content closed" : "content"}>
                <div className="header">
                    <h2 className='urbanist-500'>Штрафы</h2>
                    <UserInfo/>
<BtnGroupHeader/>
                </div>
                <div className="pag_panel">
                    <div className="pags">
                        <button onClick={() => setPageIndex(pageIndex > 1 ? (pageIndex - 1) : (pageIndex))}>Назад</button>
                        <span>{pageIndex}</span>
                        <button onClick={() => setPageIndex(Math.ceil((reqRowsCount / pageSize)) > pageIndex ? (pageIndex + 1) : (pageIndex))}>Вперед</button>
                    </div>
                    <div className="pagSizes">
                        <button onClick={() => setPageSize(5)} className={pageSize === 5 ? 'selected' : ''}>5</button>
                        <button onClick={() => setPageSize(10)} className={pageSize === 10 ? 'selected' : ''}>10</button>
                        <button onClick={() => setPageSize(15)} className={pageSize === 15 ? 'selected' : ''}>15</button>
                    </div>
                </div>
                <Paper sx={{ width: '100%', overflow: 'hidden', background: `${theme === 'light' ? 'white' : 'rgba(31, 38, 48, 1)'}` }}>
                    <TableContainer sx={{ maxHeight: "500px" }}>
                        <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                key={column.id}
                                align={column.align}
                                style={theme === 'light' ? {color: "black", background: "#DCDCDC", minWidth: column.minWidth} : {color: "white", background: "rgb(22, 29, 38)", minWidth: column.minWidth}}
                                >
                                {column.label}
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align} style={theme === 'light' ? {color: "black"} : {color: "white"}}>
                                        {column.id === 'status' 
                                            ?
                                            column.formatStatus(value)
                                            :
                                            column.id === 'created' 
                                            ?
                                            column.formatDate(value)
                                            :
                                            column.format(value)
                                        }
                                        </TableCell>
                                    );
                                    })}
                                </TableRow>
                                );
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={theme === 'light' ? {color: 'black', display: 'none'} : {color: 'white', display: 'none'}}
                    />
                </Paper>
            </div>
        </div>
    )
}