import './AddBal.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import UserInfo from '../../components/UserInfo/UserInfo';
import BtnGroupHeader from '../../components/BtnGroupHeader/BtnGroupHeader';
import { useSelector, useDispatch } from 'react-redux';
import { setUserAddBalanceWallet } from '../../storeRedux';

export default function AddBal() {
    const jwtToken = localStorage.getItem('accessToken');
    const navigate = useNavigate();
    const [walletData, setWalletData] = useState([]);
    const [error, setError] = useState('');

    const dispatch = useDispatch();
    const theme = useSelector((state) => state.APP_THEME);
    const balance = useSelector((state) => state.USER_ADD_BALANCE_WALLET);
    const show_sidebar = useSelector((state) => state.SHOW_SIDEBAR);

    useEffect(() => {
        if (!jwtToken) {
            navigate('/auth');
            localStorage.clear();
        }
    }, []);

    const setUserBalance = (some_data) => {
        const newData = some_data;
        dispatch(setUserAddBalanceWallet(newData));
    };

    useEffect(() => {
        const fetchData = async () => {
            const jwtToken = localStorage.getItem('accessToken');

            try {
                const response = await fetch('/api/v3/user_balance_deposit/create/', {
                    method: 'POST',
                    headers: {
                        'Authorization': `JWT ${jwtToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                console.log(data);
                if (data.status === "success") {
                    setUserBalance(data.address);
                } else {
                    setError(data.message);
                }
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className={theme === 'light' ? "AddBal" : "AddBal dark"}>
            <Sidebar/>
            <div className={show_sidebar ? "content closed" : "content"}>
                <div className="header">
                    <h2 className='urbanist-500'>Пополнить баланс</h2>
                    <UserInfo/>
<BtnGroupHeader/>
                </div>
                <div className="block">
                    <Link to="/addbalance">&#8656; Вернуться назад</Link>
                    <span className='warning'>! Пополнения ниже 1000 USDT не будут учитываться !</span>
                    {error.length === 0 && <span className='warning'>{error}</span>}
                    <span onClick={() => navigator.clipboard.writeText(balance)}>Ваш уникальный адрес : {balance} 
                        {balance && <svg style={{cursor: 'pointer'}} onClick={() => {navigator.clipboard.writeText(balance);alert('Скопировано')}} viewBox="0 0 24 24" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20.9983 10C20.9862 7.82497 20.8897 6.64706 20.1213 5.87868C19.2426 5 17.8284 5 15 5H12C9.17157 5 7.75736 5 6.87868 5.87868C6 6.75736 6 8.17157 6 11V16C6 18.8284 6 20.2426 6.87868 21.1213C7.75736 22 9.17157 22 12 22H15C17.8284 22 19.2426 22 20.1213 21.1213C21 20.2426 21 18.8284 21 16V15" stroke={theme === 'dark' ? 'white' : 'black'} strokeWidth="1.5" strokeLinecap="round"></path> <path d="M3 10V16C3 17.6569 4.34315 19 6 19M18 5C18 3.34315 16.6569 2 15 2H11C7.22876 2 5.34315 2 4.17157 3.17157C3.51839 3.82475 3.22937 4.69989 3.10149 6" stroke={theme === 'dark' ? 'white' : 'black'} strokeWidth="1.5" strokeLinecap="round"></path> </g></svg>}
                    </span>
                    <span>Вам необходимо в течении 30 минут отправить любую сумму на этот адрес. Пополнение происходит автоматически после 1 подтверждения транзакции.</span>
                </div>
            </div>
        </div>
    )
}
