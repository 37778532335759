import Sidebar from '../../components/Sidebar/Sidebar';
import './AddBalance.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import UserInfo from '../../components/UserInfo/UserInfo';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import BtnGroupHeader from '../../components/BtnGroupHeader/BtnGroupHeader';
import { useSelector, useDispatch } from 'react-redux';
import { setAppTheme } from '../../storeRedux';
import Alert from '@mui/material/Alert';

export default function AddBalance() {
    localStorage.setItem('page', '/addbalance');

    const dispatch = useDispatch();
    const theme = useSelector((state) => state.APP_THEME);
    const show_sidebar = useSelector((state) => state.SHOW_SIDEBAR);

    const jwtToken = localStorage.getItem('accessToken');
    const navigate = useNavigate();

    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        if (!jwtToken) {
            navigate('/auth');
            localStorage.clear();
        }
    }, [jwtToken, navigate]);

    function PopupInfo(props) {
        return (
            <div className={theme === 'dark' ? "PopupInfo dark" : "PopupInfo"}>
                <span>{props.text}</span>
                <button onClick={() => setShowPopup(false)}>Понятно</button>
            </div>
        )
    }

    const [paymentsData, setPaymentsData] = useState([]);
    const [columns, setColumns] = useState([]);

    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(1);
    const [reqRowsCount, setReqRowsCount] = useState(0);

    const fetchData = async () => {
        console.log('FetchData!!!');
        try {
            const response = await fetch('/react_admin/api/hub/userbalancedeposit/info', {
                method: 'GET',
                headers: {
                    'Authorization': `JWT ${jwtToken}`
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setPaymentsData(data.results);
            generateColumns(data.results);
            console.log(data.results);
            console.log('good!');
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const generateColumns = (data) => {
        if (data.length > 0) {
            const firstItem = data[0];
            const generatedColumns = Object.keys(firstItem).slice(1).map(key => ({
                id: key,
                label: firstItem[key]?.label || key,
                minWidth: 170,
                align: 'left',
                format: (value) => <div dangerouslySetInnerHTML={{ __html: value }} />,
                formatDate: (value) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss'),
                formatStatus: (value) => (
                    <div className={`statusCell ${value}`} style={{ border: value === 'Просрочен' ? '1px solid red' : '1px solid green' }}>
                        {value}
                    </div>
                ),
                formatBtn: (value) => (
                    value.includes(`<button type="submit" class="action-btn refresh-button"`)
                    ?
                    <button className='update_status_button' onClick={() => updateStatusIfNotEmpty(data)}>Обновить статус</button>
                    :
                    <div dangerouslySetInnerHTML={{ __html: value }} />
                )
            }));
            setColumns(generatedColumns);
        }
    };

    function createData(payment) {
        let data = {};
        for (let key in payment) {
            if (payment.hasOwnProperty(key)) {
                data[key] = payment[key].value;
            }
        }
        return data;
    }

    const rows = paymentsData.map(createData);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const updateStatusIfNotEmpty = (data) => {
        if (data.length > 0) {
            const pk = data[0].pk;
            const url = `/api/v3/user_balance_deposit/update/${pk}/`;
            const jwtToken = localStorage.getItem('accessToken');

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', 
                    'Authorization': `JWT ${jwtToken}`
                }
            })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                setShowPopup(true); 
            })
            .then(() => fetchData())
            .catch(error => {
                console.error('Error:', error); 
            });
        } else {
            console.log('No payments data available.');
        }
    }

    return (
        <div className={theme === 'light' ? "AddBalance" : "AddBalance dark"}>
            {showPopup && <Alert style={{cursor: "pointer", position: "fixed", margin: "auto", top: "16px", left: "0", right: "0", width: "max-content", zIndex: "100"}} severity="success" onClose={() => setShowPopup(false)}>Статусы обновлены!</Alert>}
            <Sidebar />
            <div className={show_sidebar ? "content closed" : "content"}>
                <div className="header">
                    <h2 className='urbanist-500'>Пополнение баланса <Link to="/addbal" className='addBal'>Пополнить</Link></h2>
                    <UserInfo />
<BtnGroupHeader/>
                </div>
                <div className="pag_panel">
                    <div className="pags">
                        <button onClick={() => setPageIndex(pageIndex > 1 ? (pageIndex - 1) : (pageIndex))}>Назад</button>
                        <span>{pageIndex}</span>
                        <button onClick={() => setPageIndex(Math.ceil((reqRowsCount / pageSize)) > pageIndex ? (pageIndex + 1) : (pageIndex))}>Вперед</button>
                    </div>
                    <div className="pagSizes">
                        <button onClick={() => setPageSize(5)} className={pageSize === 5 ? 'selected' : ''}>5</button>
                        <button onClick={() => setPageSize(10)} className={pageSize === 10 ? 'selected' : ''}>10</button>
                        <button onClick={() => setPageSize(15)} className={pageSize === 15 ? 'selected' : ''}>15</button>
                    </div>
                </div>
                <div className="container">
                    <Paper sx={{ width: '100%', overflow: 'hidden', background: `${theme === 'light' ? 'white' : 'rgba(31, 38, 48, 1)'}` }}>
                        <TableContainer sx={{ maxHeight: "500px" }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={theme === 'light' ? { color: "black", background: "#DCDCDC", minWidth: column.minWidth } : { color: "white", background: "rgb(22, 29, 38)", minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell key={column.id} align={column.align} style={theme === 'light' ? { color: "black" } : { color: "white" }}>
                                                                {
                                                                    column.id === 'created'
                                                                        ? column.formatDate(value)
                                                                        : column.id === 'status_info'
                                                                            ? column.formatStatus(value)
                                                                            : column.id === 'check_tr_status'
                                                                                ? column.formatBtn(value)
                                                                                : column.format(value)
                                                                }
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={theme === 'light' ? { color: 'black', display: 'none' } : { color: 'white', display: 'none' }}
                        />
                    </Paper>
                </div>
            </div>
        </div>
    )
}
