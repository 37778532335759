import './BtnGroupHeader.scss';
import { useSelector, useDispatch } from 'react-redux';
import { setAppTheme } from '../../storeRedux';
import { setShowSidebar } from '../../storeRedux';

export default function BtnGroupHeader() {

    const dispatch = useDispatch();
    const theme = useSelector((state) => state.APP_THEME);
    const show_sidebar = useSelector((state) => state.SHOW_SIDEBAR);

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        dispatch(setAppTheme(newTheme));
    };

    const toggleSidebar = () => {
        dispatch(setShowSidebar(!show_sidebar));
    };

    return (
        <div className="btns_group">
            <button className="change_theme" onClick={() => toggleTheme()}>
                <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g id="loopa-Product-Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="ic_fluent_dark_theme_24_filled" fill="#212121" fillRule="nonzero">
                        <path d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M12,20 L12,4 C16.418278,4 20,7.581722 20,12 C20,16.418278 16.418278,20 12,20 Z" id="color-Color">                            </path>
                        </g>
                    </g>
                </svg>
            </button>
            <button className={!show_sidebar ? 'burger_menu' : 'burger_menu reversed'} onClick={() => toggleSidebar()}>
                <svg viewBox="0 0 24 24" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 18L20 18" stroke="#000000" strokeWidth="2" strokeLinecap="round"></path> <path d="M4 12L20 12" stroke="#000000" strokeWidth="2" strokeLinecap="round"></path> <path d="M4 6L20 6" stroke="#000000" strokeWidth="2" strokeLinecap="round"></path> </g></svg>
            </button>
        </div>
    )
}